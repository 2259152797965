// Assets
import { Helmet } from "react-helmet"
import OrderButton from "../base/order-button/order-button"
import syreskader from "../../assets/images/articles/syreskader.webp"

const Syreskader = () => {
  return (
    <div className="info w-full center-content p-8 text-dark">
      <Helmet>
        <title>Forebygging og behandling av syreskader på tennene</title>
        <meta
          name="description"
          content="Syreskader på tennene, også kjent som dentale erosjoner, er et økende problem blant både voksne og barn. Les mer om årsaker, symptomer, behandling og forebygging her."
        />
        <link
          rel="canonical"
          href="https://ladetannhelsesenter.no/info/syreskader"
        />
      </Helmet>
      <div className="max-w-screen-xl">
        <div className="center-content flex-col mb-8 text-center">
          <h1>Forebygging og behandling av syreskader på tennene</h1>
          <p className={`!mb-8 mt-2`}>
            Syreskader på tennene, også kjent som dentale erosjoner, er et
            økende problem blant både voksne og barn. Dette kan føre til
            betydelige tannhelseproblemer hvis det ikke behandles riktig. I
            denne artikkelen vil vi utforske årsakene til syreskader, hvordan de
            kan forebygges, og hvilke behandlingsmuligheter som finnes.
          </p>
          <img
            src={syreskader}
            alt="syreskader"
            className="w-full max-h-[60vh] object-cover object-center"
          />
        </div>
        <div className="mb-8">
          <h2>Hva er syreskader på tennene?</h2>
          <p>
            Syreskader på tennene oppstår når syre etser bort emaljen, det
            beskyttende ytre laget på tennene. Dette kan føre til at tennene
            blir følsomme, får ising, og i alvorlige tilfeller, tannråte.
            Emaljen beskytter tennene mot daglig slitasje fra mat og drikke, og
            når den brytes ned, blir tennene mer utsatt for skader.
          </p>
        </div>
        <div className="mb-8">
          <h2>Årsaker til syreskader</h2>
          <p>Syreskader på tennene kan skyldes flere faktorer:</p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Mat og drikke: Hyppig inntak av syreholdige matvarer og drikker
              som brus, sportsdrikker, sitrusfrukter, og eddik kan øke risikoen
              for syreskader. Disse produktene har lav pH-verdi, noe som betyr
              at de er svært sure.
            </li>
            <li>
              Sure oppstøt: Tilbakevendende syre fra magen, kjent som
              gastroøsofageal reflukssykdom (GERD), kan også føre til syreskader
              på tennene. Magesyre som kommer i kontakt med tennene kan være
              svært skadelig.
            </li>
            <li>
              Munntørrhet: Spytt hjelper til med å nøytralisere syre i munnen.
              Når spyttproduksjonen er redusert, som det kan være ved visse
              medisiner eller medisinske tilstander, øker risikoen for
              syreskader.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Symptomer på syreskader</h2>
          <p>Tidlige symptomer på syreskader inkluderer:</p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Ising i tennene: Spesielt ved inntak av varme, kalde, søte eller
              sure matvarer og drikker.
            </li>
            <li>
              Misfarging: Tennene kan få en gulere tone ettersom emaljen slites
              bort og dentinet under blir eksponert.
            </li>
            <li>
              Gjennomsiktige kanter: Tannemaljen kan bli så tynn at tennene ser
              gjennomsiktige ut langs kantene.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Forebygging av syreskader</h2>
          <p>
            Det er flere tiltak du kan ta for å forebygge syreskader på tennene:
          </p>
          <h3>Begrens inntak av syreholdige matvarer og drikker</h3>
          <p>
            Prøv å redusere forbruket av brus, sitrusfrukter, og andre
            syreholdige produkter. Hvis du drikker syreholdige drikker, bruk et
            sugerør for å minimere kontakten med tennene.
          </p>
          <h4 className="font-bold">pH-verdi i Vanlige Matvarer</h4>
          <p>
            pH-verdien er et mål på hvor surt eller basisk et stoff er. Skalaen
            går fra 0 til 14, hvor 7 er nøytral, under 7 er surt, og over 7 er
            basisk. Matvarer med lav pH-verdi (surt) kan bidra til syreskader på
            tennene. Her er noen eksempler på pH-verdien til vanlige matvarer:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>Brus: pH 2.5</li>
            <li>Appelsinjuice: pH 3.5</li>
            <li>Eple: pH 3.2</li>
            <li>Yoghurt: pH 4.5</li>
            <li>Mager melk: pH 6.7</li>
            <li>Rent vann: pH 7.0</li>
          </ul>
          <h3>Skyll munnen etter inntak</h3>
          <p>
            Skyll munnen med vann etter å ha spist eller drukket noe syreholdig.
            Dette hjelper til med å nøytralisere syren og redusere
            skadepotensialet.
          </p>
          <h3>God munnhygiene</h3>
          <p>
            Puss tennene med fluortannkrem to ganger daglig og bruk tanntråd
            regelmessig. Fluor hjelper til med å styrke emaljen og beskytte
            tennene mot syreskader. Vent minst 30 minutter etter å ha spist
            eller drukket syreholdige produkter før du pusser tennene, da
            emaljen kan være svekket rett etter syreeksponering.
          </p>
          <h3>Regelmessige tannlegebesøk</h3>
          <p>
            Oppsøk tannlege regelmessig for å oppdage tidlige tegn på syreskader
            og få profesjonell råd om hvordan du best kan beskytte tennene dine.
            En tannlege kan tilby forebyggende behandlinger, som fluorlakk, for
            å styrke emaljen.
          </p>
        </div>
        <div className="mb-8">
          <h2>Syreskader tenner behandling</h2>
          <p>
            Hvis syreskader har oppstått, er det flere behandlingsalternativer
            som kan hjelpe til med å reparere og beskytte tennene:
          </p>
          <h3>Fluorbehandling</h3>
          <p>
            Fluorbehandlinger hos tannlege kan bidra til å remineralisere og
            styrke emaljen, noe som kan forebygge ytterligere skader.
          </p>
          <h3>Tannfyllinger og kroner</h3>
          <p>
            For mer omfattende skader kan tannfyllinger eller kroner være
            nødvendige for å gjenopprette tannens struktur og funksjon. Dette
            kan være spesielt viktig hvis syreskadene har ført til hull eller
            tap av tannsubstans.
          </p>
          <h3>Bonding</h3>
          <p>
            Bonding innebærer å legge et lag komposittmateriale over de skadede
            områdene for å beskytte tannen og forbedre estetikken.
          </p>
        </div>
        <div className="mb-8">
          <h2>Syreskade tenner barn</h2>
          <p>
            Barn kan også være utsatt for syreskader, spesielt hvis de har et
            høyt inntak av syreholdige drikker som brus og juice. Det er viktig
            å lære barn gode vaner for tannhelse tidlig, inkludert å begrense
            syreholdige drikker, skylle munnen med vann etter inntak av syre, og
            opprettholde god munnhygiene.
          </p>
        </div>
        <div className="mb-8">
          <h2>Syreskader tenner behandling pris</h2>
          <p>
            Kostnaden for behandling av syreskader kan variere avhengig av
            skadeomfanget og hvilke behandlinger som er nødvendige. Det er
            viktig å diskutere alternativer og priser med tannlegen din for å
            finne den beste løsningen som passer dine behov og budsjett.
          </p>
        </div>
        <div className="mb-8">
          <h2>Forebyggende tiltak</h2>
          <p>
            Å forebygge syreskader er alltid bedre enn å måtte behandle dem
            senere. Her er noen forebyggende tiltak:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Hold et øye med dietten: Unngå hyppig inntak av syreholdige
              matvarer og drikker. Hvis du inntar dem, gjør det sammen med
              måltider for å redusere syrepåvirkningen.
            </li>
            <li>
              Bruk fluorprodukter: Tannkrem og munnskyllevann med fluor kan
              bidra til å styrke emaljen.
            </li>
            <li>
              God munnhygiene: Sørg for å pusse tennene to ganger om dagen med
              fluortannkrem og bruke tanntråd daglig.
            </li>
            <li>
              Besøk tannlegen regelmessig: Regelmessige tannlegebesøk kan hjelpe
              til med å oppdage og behandle syreskader tidlig.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Helfo og dekning av behandling for syreskader</h2>
          <p>
            Helfo kan bidra til å dekke kostnadene ved behandling av syreskader
            på tennene. Dette er spesielt relevant for pasienter som har
            syreskader på grunn av medisinske tilstander som GERD eller
            munntørrhet. Helfo dekker behandlinger som:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              <strong>Fluorbehandling</strong>: Dekker kostnadene ved
              profesjonell påføring av fluor.
            </li>
            <li>
              <strong>Tannfyllinger og kroner</strong>: Deler av kostnadene for
              fyllinger og kroner kan dekkes.
            </li>
            <li>
              <strong>Medisinsk begrunnede behandlinger</strong>: Hvis
              syreskadene skyldes medisinske tilstander, kan Helfo dekke en
              betydelig del av behandlingskostnadene.
            </li>
          </ul>
          <p>
            Det er viktig å diskutere behandlingsalternativer og priser med
            tannlegen din, og tannlegen kan hjelpe med å søke om refusjon fra
            Helfo. På den måten kan du sikre at du får nødvendig behandling uten
            at det blir for dyrt.
          </p>
        </div>
        <div className="mb-8">
          <h2>Ta vare på tennene dine - forebygg syreskader</h2>
          <p>
            Syreskader på tennene er en alvorlig tilstand som kan ha stor
            innvirkning på din tannhelse. Ved å forstå årsakene, symptomene, og
            forebyggende tiltak, kan du beskytte tennene dine mot skade. Hvis du
            allerede har syreskader, finnes det effektive
            behandlingsalternativer som kan hjelpe deg å gjenopprette
            tannhelsen. Kontakt din tannlege for en konsultasjon og bestill time
            for å sikre at tennene dine får den beste omsorgen.
          </p>
          <p>
            Vi på Lade Tannhelsesenter tilbyr et bredt spekter av tjenester og
            behandlinger for å holde tennene dine sunne og sterke!
          </p>
        </div>
        <div className="center-content mt-12 mb-10">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default Syreskader
