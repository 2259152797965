// Assets
import { Helmet } from "react-helmet"
import OrderButton from "../base/order-button/order-button"
import GingivittImg from "../../assets/images/articles/gingivitt.webp"

const Gingivitt = () => {
  return (
    <div className="info w-full center-content p-8 text-dark">
      <Helmet>
        <title>Gingivitt: Symptomer, årsaker og behandling</title>
        <meta
          name="description"
          content="Gingivitt er en betennelse i tannkjøttet som kan føre til mer
          alvorlige tannkjøttsykdommer. Lær om årsaker, gingivitt symptomer og
          effektive behandlinger her."
        />
        <link
          rel="canonical"
          href="https://ladetannhelsesenter.no/info/gingivitt"
        />
      </Helmet>
      <div className="max-w-screen-xl">
        <div className="center-content flex-col mb-8 text-center">
          <h1>Gingivitt: Symptomer, årsaker og behandling</h1>
          <p className={`!mb-8 mt-2`}>
            Betennelse i tannkjøttet som kan føre til alvorlige
            tannkjøttsykdommer
          </p>
          <img
            src={GingivittImg}
            alt="gingivitt tannlege trondheim lade tannhelsesenter"
            className="w-full max-h-[60vh] object-cover object-center"
          />
        </div>
        <div className="mb-8">
          <p>
            Har du lagt merke til at tannkjøttet blør når du pusser tennene?
            Eller sliter du med dårlig ånde selv etter god munnhygiene? Dette
            kan være tegn på en betennelse i tannkjøttet. Selv om tilstanden er
            vanlig, kan den føre til alvorlige tannproblemer dersom den ikke
            behandles. Heldigvis finnes det effektive måter å forebygge og
            behandle problemet på.
          </p>
        </div>
        <div className="mb-8">
          <h2>Gingivitt symptomer - hva bør du se etter?</h2>
          <p>
            Det første tegnet på betent tannkjøtt er ofte at tannkjøttet blør
            når du pusser tennene eller bruker tanntråd. Andre symptomer
            inkluderer:
          </p>
          <ul className="list-decimal pl-8 mb-4">
            <li>Rødt, hovent og ømt tannkjøtt</li>
            <li>
              Vedvarende dårlig ånde Tilbaketrukket tannkjøtt, som kan gjøre
              tennene mer følsomme
            </li>
            <li>Mykt eller svampaktig tannkjøtt</li>
          </ul>
          <p>
            Dersom du opplever noen av disse symptomene, er det viktig å ta grep
            tidlig. Behandlingen er mest effektiv i de tidlige stadiene, før
            tilstanden utvikler seg til en mer alvorlig tannkjøttsykdom.
          </p>
        </div>
        <div className="mb-8">
          <h2>Årsaker til tannkjøttbetennelse</h2>
          <p>
            Den vanligste årsaken til betennelse i tannkjøttet er opphopning av
            plakk og tannstein. Plakk er et klebrig bakteriebelegg som dannes på
            tennene etter måltider. Dersom det ikke fjernes ved grundig tannpuss
            og bruk av tanntråd, kan det føre til irritasjon og betennelse.
            Andre faktorer som øker risikoen for utvikling av gingivitt
            inkluderer:
          </p>
          <ul className="list-decimal pl-8 mb-4">
            <li>Dårlig munnhygiene</li>
            <li>Røyking</li>
            <li>Hormonelle endringer (for eksempel under graviditet)</li>
            <li>Enkelte medisiner som reduserer spyttproduksjonen</li>
            <li>Diabetes eller andre sykdommer som svekker immunforsvaret</li>
          </ul>
          <p>
            En sunn munnhygiene-rutine morgen og kveld er avgjørende for å
            forebygge betent tannkjøtt.
          </p>
        </div>
        <div className="mb-8">
          <h2>Gingivitt behandlinger - hvordan bli kvitt betennelsen?</h2>
          <p>
            Den gode nyheten er at betennelse i tannkjøttet kan reverseres
            dersom det behandles tidlig. Behandlingen består av både
            profesjonell tannrengjøring og gode rutiner hjemme.
          </p>
          <h3>Behandling hos tannlege eller tannpleier</h3>
          <p>
            Dersom plakk og tannstein har bygget seg opp rundt tennene, må dette
            fjernes profesjonelt. Tannlegen eller tannpleieren vil bruke
            spesielle instrumenter for å fjerne plakket, samt gi råd om videre
            forebygging. I noen tilfeller kan det være nødvendig med mer
            omfattende behandling dersom tilstanden har utviklet seg.
          </p>
          <h3>Egenbehandling hjemme</h3>
          <p>
            For å opprettholde friskt tannkjøtt og forhindre tilbakefall, er det
            viktig å ha en god munnhygiene-rutine:
          </p>
          <ul className="list-decimal pl-8 mb-4">
            <li>
              <b>Puss tennene</b> grundig morgen og kveld med en myk tannbørste
              og fluortannkrem.
            </li>
            <li>
              <b>Bruk tanntråd</b> eller mellomromsbørster daglig for å fjerne
              plakk mellom tennene.
            </li>
            <li>
              <b>Skyll med antibakteriell munnskyll</b> for å redusere
              bakterievekst.
            </li>
            <li>
              <b>Unngå røyking</b>, da det svekker kroppens evne til å bekjempe
              infeksjoner i tannkjøttet.
            </li>
            <li>
              <b>Ha regelmessige tannlegebesøk</b> for å oppdage eventuelle
              problemer tidlig.
            </li>
          </ul>
          <p>
            Dersom tannkjøttet allerede er betent, kan det ta noen uker med god
            pleie før det blir friskt igjen.
          </p>
        </div>
        <div className="mb-8">
          <h2>Hva skjer hvis betennelsen ikke behandles?</h2>
          <p>
            Dersom du ignorerer tegn på betennelse i tannkjøttet, kan tilstanden
            utvikle seg til periodontitt - en mer alvorlig tannkjøttsykdom som
            kan føre til tap av tenner. Periodontitt oppstår når bakterier sprer
            seg dypere ned i tannkjøttet, noe som kan skade det omkringliggende
            vevet og beinet.
          </p>
          <h3>
            <b>Tegn på mer alvorlige tannkjøttproblemer inkluderer:</b>
          </h3>

          <ul className="list-decimal pl-8 mb-4">
            <li>Økende smerte og ømhet</li>
            <li>Løsere tenner</li>
            <li>Puss mellom tennene og tannkjøttet</li>
            <li>Tilbaketrukket tannkjøtt som gjør tennene mer utsatte</li>
          </ul>
          <p>
            For å unngå dette er det viktig å handle tidlig dersom du merker
            tegn på tannkjøttbetennelse.
          </p>
        </div>
        <div className="mb-8">
          <h2>Slik tar du vare på tannkjøttet ditt</h2>
          <p>
            God munnhygiene og forebygging er nøkkelen til å unngå
            tannkjøttproblemer. Her er noen enkle, men effektive vaner du kan
            innføre i din daglige rutine:
          </p>
          <ul className="list-decimal pl-8 mb-4">
            <li>Puss tennene minst to ganger daglig</li>
            <li>Bruk tanntråd eller mellomromsbørster</li>
            <li>Ha et balansert kosthold som styrker tannkjøttet</li>
            <li>Besøk tannlegen jevnlig for kontroll og rens</li>
          </ul>
          <p>
            Hvis du allerede har symptomer på betennelse, bør du ta grep nå for
            å gjenopprette sunn tannhelse.
          </p>
        </div>
        <div className="mb-8">
          <h2>Tidlig behandling gir best resultat</h2>
          <p>
            Gingivitt er en vanlig, men behandlingsbar betennelse i tannkjøttet.
            Typiske tegn inkluderer blødende tannkjøtt, dårlig ånde og rødhet
            rundt tennene. Hovedårsaken er opphopning av plakk og tannstein, men
            med riktig behandling kan tilstanden reverseres. For å unngå mer
            alvorlige problemer som periodontitt, er det viktig å følge gode
            munnhygiene-rutiner og besøke tannlegen regelmessig.
          </p>
        </div>
        <div className="mb-8">
          <p>
            <b>Trenger du hjelp?</b> Bestill en time hos vår klinikk i dag, og
            få hjelp til å bevare tennene dine.
          </p>
        </div>
        <div className="center-content mt-12 mb-10">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default Gingivitt
