import { Helmet } from "react-helmet"
import Employees from "../../assets/images/ansatt_tannleger_lade_tannhelse_senter.webp"

const AboutUs = () => {
  return (
    <div className="center-content flex-col px-2">
      <Helmet>
        <title>Om Oss</title>
        <link
          rel="canonical"
          href="https://ladetannhelsesenter.no/info/om-oss"
        />
      </Helmet>
      <div className="max-w-screen-lg pb-8">
        <div className="pb-8 md:pt-8">
          <h1 className="text-3xl text-primary-accent font-bold">Om Oss</h1>
        </div>
        <div className="flex flex-col-reverse lg:flex-row items-center lg:items-start">
          <div className="flex-1 text-lg mb-8">
            <h2 className="text-2xl mb-4">
              Velkommen til Lade Tannhelsesenter
            </h2>
            <p className="mb-4">
              Vi forstår at et tannlegebesøk kan være skremmende for noen,
              derfor har vi skapt et beroligende miljø med myke farger og
              moderne innredning for å gjøre klinikken så lite tannlegekontor
              aktig som mulig.
            </p>
            <p>
              Vår klinikk er utstyrt med moderne teknologi for å sikre at du får
              den beste behandlingen. Vi vet at tannhelsen din er viktig, og vi
              er her for å hjelpe deg med å oppnå og opprettholde et sunt smil
              resten av livet.
            </p>
          </div>
          <img
            className="w-64 min-w-[50%] lg:ml-8 lg:w-1/3 rounded-xl mb-8"
            src={Employees}
            alt="tannlegeskrekk"
          />
        </div>
      </div>
    </div>
  )
}

export default AboutUs
