import { Helmet } from "react-helmet"
import OrderButton from "../base/order-button/order-button"

const Finansiering = () => {
  return (
    <div className="center-content flex-col">
      <Helmet>
        <title>Finansiering</title>
        <link
          rel="canonical"
          href="https://ladetannhelsesenter.no/info/finansiering"
        />
      </Helmet>
      <div className="max-w-screen-lg px-4">
        <div className="py-8">
          <h1 className="text-3xl text-primary-accent font-bold">
            Finansiering
          </h1>
        </div>
        <div>
          <div className="grid gap-8">
            <div className="text-lg">
              <p className="mb-4">
                Hos oss kan du delbetale via resursbank - Finansering av
                tannbehandling for deg som privatperson.
              </p>
              <p className="mb-4">
                Tannkonto er en smart løsning for deg som ønsker å delbetale din
                tannbehandling. Du slipper å betale tannlegebesøket med en gang
                og mottatt faktura først måneden etter. Du kan da velge å betale
                fakturaen i sin helhet eller dele opp betalingen.
              </p>
              <h2 className="text-2xl text-primary-accent mb-2">
                Dine fordeler
              </h2>
              <p className="mb-4">
                Med tannkonto mottar du fakturen i midten av måneden etter
                besøket hos tannlegen, med betalingsfrist siste dag de måneden.
                Du har altså 30 til 60 dager fra besøket hos tannlegen til
                fakturaen skal betales. På fakturaen vil du se dine
                betalingsalternativer, hvor du kan betale alt på en gang eller
                dele opp betalingen.
              </p>
              <h2 className="text-2xl text-primary-accent mb-2">
                Hvordan fungerer det
              </h2>
              <p className="mb-4">
                Tannkonto tilbyr finansiering av tannbehandling. Uavhenging av
                tannbehandling vil du få muligheten til fleksible betaling. Det
                vil si at du kan velge å dele opp fakturaen slik det passer deg.
                Du har mange alternativer å velge mellom, blant annet kan du
                dele opp fakturaen over 12 måneder helt rentefritt
              </p>
              <h2 className="text-2xl text-primary-accent mb-2">
                Ofte stilte spørsmål
              </h2>
              <div className="mb-4">
                <strong>Hva kreves for å få kreditt?</strong>
                <br />- Du må være minst 18 år, være Registert i Norge, ha fast
                ansettelse og være fri for betalingsanmerkninger.
                <br />
                <br />
                <strong>Når får jeg min faktura?</strong>
                <br />- Fakturaen kommer måneden etter ditt tannlegebesøk. Velg
                det betalingsalternative som passer deg best, fakturaen skal
                være betalt innen sist bankdag i samme måned. Eksempel: Du går
                til tannlegen 3. juni og legger inn betalingen på din Tannkonto.
                Da kommer fakturaen i midten av juli, og skal betales i slutten
                av juli. Har du bestilt flere tannlegebesøk? Da kan du velge å
                legge alle betalinger på Tannlegekontoen og da samles alle på
                samme faktura. <br />
                <br />
                <strong>Hvordan søker jeg om en Tannkonto?</strong>
                <br />- Du kan søke om Tannkonto ved å sende SMS «tannkonto» til
                27 700 eller ved å snakke med personalet på klinikken.
                <br />
                <br />
                <strong>Hvilken alternativer har jeg for delbetaling?</strong>
                <br />- Dersom du ønsker å dele opp betalingen kan du velge
                mellom 1 til 12 måneder rentefritt eller opptil 54 måneder med
                rente. Hvordan du ønsker å betale bestemmer du først når du
                mottar din faktura måneden etter din behandling. <br />
                <br />
                <strong>Kan man få faktura hos tannlegen?</strong>
                <br />- Ja, med Tannkonto kan du betale ditt tannlegebesøk ved
                hjelp av faktura og du har mulighet til å dele opp betalingen
                over en lengre periode.
              </div>
            </div>
          </div>
        </div>
        <div className="center-content my-8">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default Finansiering
