import ladeTannhelsesenter from "../assets/images/lade_tannhelsesenter.webp"
import invisalign from "../assets/images/articles/invisalign.webp"
import tanntrekking from "../assets/images/articles/tanntrekking.webp"
import toothImplant from "../assets/images/articles/tannimplantat.webp"
import tannlegevakt from "../assets/images/articles/tannlegevakt.webp"
import syreskader from "../assets/images/articles/syreskader.webp"
import skallfasetter from "../assets/images/articles/skallfasetter.webp"
import tannbleking from "../assets/images/articles/tannbleking.webp"
import gingivitt from "../assets/images/articles/gingivitt.webp"
import ising from "../assets/images/articles/ising.webp"
import tmd from "../assets/images/articles/tmd.webp"
import gnissing from "../assets/images/articles/gnissing.webp"

export type Article = {
  id: string
  title: string
  description: string
  short: string
  image: string
}

const articles: Article[] = [
  {
    id: "ising",
    title: "Ising",
    description: "Årsaker, forebygging og behandling",
    short:
      "Opplever du ising i tennene? Lær om årsaker, forebygging og behandling for å beskytte tennene dine og redusere ubehag.",
    image: ising,
  },
  {
    id: "tmd",
    title: "TMD",
    description: "Årsaker, symptomer og behandling",
    short:
      "Opplever du smerter i kjeven? Lær om symptomer, årsaker og behandling av TMD for å redusere smerter og forbedre kjeveleddfunksjonen.",
    image: tmd,
  },
  {
    id: "gnissing",
    title: "Gnissing",
    description: "Slitasje og smerter i kjeven",
    short:
      "Tanngnissing kan føre til slitasje og smerter i kjeven. Lær hvordan en bittskinne kan beskytte tennene, redusere ubehag og om du kan få HELFO-refusjon.",
    image: gnissing,
  },
  {
    id: "gingivitt",
    title: "Gingivitt",
    description: "Behandling av betennelse i tannkjøttet",
    short:
      "Gingivitt er en betennelse i tannkjøttet som kan føre til alvorlige tannkjøttsykdommer.",
    image: gingivitt,
  },
  {
    id: "syreskader",
    title: "Syreskader",
    description: "Forebygging og behandling av syreskader på tennene",
    short:
      "Syreskader på tennene, også kjent som dentale erosjoner, kan føre til betydelige tannhelseproblemer.",
    image: syreskader,
  },
  {
    id: "usynlig-regulering",
    title: "Usynlig regulering",
    description: "Invisalign og andre tannreguleringstyper",
    short:
      "Usynlig regulering, også kjent som Invisalign eller skjult regulering er en tilnærming til tannregulering uten synlige metallkomponenter.",
    image: invisalign,
  },
  {
    id: "tannlegeskrekk",
    title: "Tannlegeskrekk",
    description: "Årsaker og behandling",
    short:
      "Tannlegeskrekk, eller odontofobi, er en utbredt angst for tannbehandling som berører mange mennesker.",
    image: ladeTannhelsesenter,
  },
  {
    id: "tannbleking",
    title: "Tannbleking",
    description: "Tennbleking hos tannlege eller hjemme",
    short:
      "Fjerning av misfarging for langvarig hvitere tenner og et strålende smil.",
    image: tannbleking,
  },
  {
    id: "tanntrekking",
    title: "Tanntrekking",
    description: "Fjerning av tenner",
    short:
      "Fjerning av tenner er en vanlig behandling som forhindrer skader og smerter ved uønsket tanntilstand.",
    image: tanntrekking,
  },
  {
    id: "tannimplantat",
    title: "Tannimplantat",
    description: "Erstatning av tenner",
    short:
      "Tannimplantater representerer den mest avanserte teknologien for å erstatte tapte tenner og gjenopprette både funksjon og estetikk i munnen.",
    image: toothImplant,
  },
  {
    id: "skallfasetter",
    title: "Skallfasetter",
    description: "Estetisk tannbehandling",
    short:
      "Estetisk tannbehandling med skallfasetter er en populær metode for penere tenner.",
    image: skallfasetter,
  },
  {
    id: "tannlegevakt",
    title: "Tannlegevakt",
    description: "Akutt behov",
    short: "Tannlegevakt behandler behov utenfor ordinær åpningstid.",
    image: tannlegevakt,
  },
]

export default articles
