// Components
import AboutUs from "../components/info/about-us"
import Confirmation from "../components/confirmation/confirmation"
import Cookies from "../components/cookies/cookies"
import Dashboard from "../components/dashboard/dashboard"
import Finansiering from "../components/info/finansiering"
import Gingivitt from "../components/info/gingivitt"
import Info from "../components/info/info"
import Invisalign from "../components/info/invisalign"
import Jobs from "../components/jobs/jobs"
import NotFound from "../components/not-found/not-found"
import Order from "../components/order/order"
import Prices from "../components/prices/prices"
import PrivacyPolicy from "../components/privacy-policy/privacy-policy"
import Skallfasetter from "../components/info/skallfasetter"
import Syreskader from "../components/info/syreskader"
import Tannbleking from "../components/info/tannbleking"
import Tannimplantat from "../components/info/tannimplantat"
import Tannlegeskrekk from "../components/info/tannlegeskrekk"
import Tannlegevakt from "../components/info/tannlegevakt"
import Tanntrekking from "../components/info/tanntrekking"
import Topical from "../components/topical/topical"
import Treatments from "../components/info/treatments"
import Ising from "../components/info/ising"
import TMD from "../components/info/tmd"
import Gnissing from "../components/info/gnissing"

type Route = {
  path: string
  changefreq: string
  priority: number
  component: (props: any) => JSX.Element
  index?: boolean
}

export const publicRoutes: Route[] = [
  { path: "", changefreq: "monthly", priority: 0.5, component: Dashboard },
  {
    path: "personvern",
    changefreq: "monthly",
    priority: 1,
    component: PrivacyPolicy,
  },
  { path: "cookies", changefreq: "monthly", priority: 0.5, component: Cookies },
  { path: "bestill", changefreq: "monthly", priority: 0.9, component: Order },
  {
    path: "bekreftelse",
    changefreq: "monthly",
    priority: 0.3,
    component: Confirmation,
  },
  { path: "priser", changefreq: "weekly", priority: 0.8, component: Prices },
  { path: "aktuelt", changefreq: "weekly", priority: 0.7, component: Topical },
  {
    path: "info/usynlig-regulering",
    changefreq: "monthly",
    priority: 0.5,
    component: Invisalign,
  },
  {
    path: "info/tanntrekking",
    changefreq: "monthly",
    priority: 0.5,
    component: Tanntrekking,
  },
  {
    path: "info/tannimplantat",
    changefreq: "monthly",
    priority: 0.5,
    component: Tannimplantat,
  },
  {
    path: "info/om-oss",
    changefreq: "monthly",
    priority: 0.5,
    component: AboutUs,
  },
  {
    path: "info/tekst/:textId",
    changefreq: "monthly",
    priority: 0.5,
    component: Info,
  },
  {
    path: "info/behandlinger/:treatment?",
    changefreq: "monthly",
    priority: 0.8,
    component: Treatments,
  },
  {
    path: "info/tannlegeskrekk",
    changefreq: "monthly",
    priority: 0.5,
    component: Tannlegeskrekk,
  },
  {
    path: "info/tannlegevakt",
    changefreq: "monthly",
    priority: 0.5,
    component: Tannlegevakt,
  },
  {
    path: "info/stillinger",
    changefreq: "monthly",
    priority: 0.5,
    component: Jobs,
  },
  {
    path: "info/finansiering",
    changefreq: "monthly",
    priority: 0.5,
    component: Finansiering,
  },
  {
    path: "info/syreskader",
    changefreq: "monthly",
    priority: 0.5,
    component: Syreskader,
  },
  {
    path: "info/skallfasetter",
    changefreq: "monthly",
    priority: 0.5,
    component: Skallfasetter,
  },
  {
    path: "info/tannbleking",
    changefreq: "monthly",
    priority: 0.5,
    component: Tannbleking,
  },
  {
    path: "info/gingivitt",
    changefreq: "monthly",
    priority: 0.5,
    component: Gingivitt,
  },
  {
    path: "info/ising",
    changefreq: "monthly",
    priority: 0.5,
    component: Ising,
  },
  {
    path: "info/tmd",
    changefreq: "monthly",
    priority: 0.5,
    component: TMD,
  },
  {
    path: "info/gnissing",
    changefreq: "monthly",
    priority: 0.5,
    component: Gnissing,
  },
  { path: "*", changefreq: "yearly", priority: 0.1, component: NotFound },
]
