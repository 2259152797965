// Assets
import { Helmet } from "react-helmet"
import OrderButton from "../base/order-button/order-button"
import IsingImg from "../../assets/images/articles/ising.webp"

const Ising = () => {
  return (
    <div className="info w-full center-content p-8 text-dark">
      <Helmet>
        <title>Ising i tennene: Årsaker, forebygging og behandling</title>
        <meta
          name="description"
          content="Opplever du ising i tennene? Lær om årsaker, forebygging og behandling for å beskytte tennene dine og redusere ubehag."
        />
        <link
          rel="canonical"
          href="https://ladetannhelsesenter.no/info/ising"
        />
      </Helmet>
      <div className="max-w-screen-xl">
        <div className="center-content flex-col mb-8 text-center">
          <h1>Ising i tennene: Årsaker, forebygging og behandling</h1>
          <p className={`!mb-8 mt-2`}>
            Opplever du ising i tennene? Lær om årsaker, forebygging og
            behandling for å beskytte tennene dine og redusere ubehag.
          </p>
          <img
            src={IsingImg}
            alt="ising tannlege trondheim lade tannhelsesenter"
            className="w-full max-h-[60vh] object-cover object-center"
          />
        </div>
        <div className="mb-8">
          <h2>Hvorfor opplever vi ising i tennene?</h2>
          <p>
            Ising i tennene er en skarp, kortvarig smerte som oppstår når
            tennene kommer i kontakt med noe kaldt, varmt, søtt eller surt.
            Dette kan være ubehagelig og påvirke dagliglivet, spesielt hvis det
            skjer ofte. Mange opplever ising i tenner fra tid til annen, men
            hvis problemet vedvarer, kan det være et tegn på en underliggende
            tannhelseutfordring. Det finnes heldigvis gode løsninger for ising i
            tennene som kan redusere ubehaget og beskytte tennene dine på lang
            sikt.
          </p>
        </div>
        <div className="mb-8">
          <h2>Årsaker til ising i tennene</h2>
          <p>
            Ising i tennene skyldes ofte at tannens beskyttende lag er svekket,
            slik at små kanaler i tannbenet (dentin) eksponeres. Når disse
            kanalene blir utsatt for ytre stimuli som kald luft eller kaldt
            drikke, kan det forårsake ising. Noen av de vanligste årsakene til
            ising i tennene inkluderer:
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li>
              <b>Slitasje på emaljen</b> - Hard tannbørsting eller syreskader
              kan tære på emaljen, noe som gjør tennene mer følsomme.
            </li>
            <li>
              <b>Tannkjøttet trekker seg tilbake</b> - Når tannkjøttet trekker
              seg tilbake, blir tannhalsene eksponert. Dette området er ikke
              dekket av emalje og kan gi økt følsomhet.
            </li>
            <li>
              <b>Hull i tenner</b> - Karies kan forårsake ising, spesielt hvis
              hullet er dypt nok til å påvirke nervene i tannen.
            </li>
            <li>
              <b>Skader på tennene</b> - Sprekker eller frakturer i tennene kan
              føre til at nervebanene blir mer eksponert.
            </li>
            <li>
              <b>Tannbleking</b> - Noen opplever ising i tennene etter
              tannbleking, særlig hvis blekemiddelet er sterkt eller brukes
              ofte.
            </li>
          </ul>
          <h3>Hva hjelper mot ising i tennene?</h3>
          <p>
            Hvis du ofte opplever ising i tennene, finnes det flere tiltak du
            kan ta for å redusere ubehaget.
          </p>
          <ul className="list-decimal pl-8 mb-4 marker:font-bold">
            <li className="mb-2">
              <p className="!mb-1">
                <b>Bruk en myk tannbørste og riktig pusseteknikk</b>
              </p>
              Mange pusser tennene for hardt, noe som kan slite ned emaljen og
              skade tannkjøttet. En myk tannbørste og skånsom børsteteknikk kan
              bidra til å beskytte tennene.
            </li>
            <li className="mb-2">
              <p className="!mb-1">
                <b>Velg en tannkrem mot sensitive tenner</b>
              </p>
              Det finnes spesialtannkremer som er laget for å beskytte
              tannoverflaten og redusere ising. Disse inneholder ofte
              ingredienser som tetter de små kanalene i dentinet og blokkerer
              smertefølelsen.
            </li>
            <li className="mb-2">
              <p className="!mb-1">
                <b>Unngå syreholdig mat og drikke</b>
              </p>
              Hyppig inntak av sure matvarer og drikke, som brus, sitrusfrukter
              og vin, kan tære på emaljen. Det kan være lurt å skylle munnen med
              vann etter inntak av slike produkter for å beskytte tennene.
            </li>
            <li className="mb-2">
              <p className="!mb-1">
                <b>Bruk tannskinne hvis du gnisser tenner</b>
              </p>
              Tanngnissing kan føre til slitasje på emaljen og øke risikoen for
              ising i tennene. En tannskinne tilpasset av tannlegen kan beskytte
              tennene dine om natten.
            </li>
            <li>
              <p className="!mb-1">
                <b>Fluorbehandling hos tannlegen</b>
              </p>
              Hvis du har vedvarende ising i tennene, kan en tannlege påføre
              fluor for å styrke emaljen og redusere følsomheten.
            </li>
          </ul>
          <h3>Ising i tennene: Behandling hos tannlegen</h3>
          <p>
            Hvis hjemmetiltak ikke hjelper, finnes det flere profesjonelle
            behandlingsmetoder som tannlegen kan utføre:
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li>
              <b>Fluorlakk</b> - En beskyttende lakk som påføres tennene for å
              redusere ising.
            </li>
            <li>
              <b>Tannfylling</b> - Hvis isingen skyldes hull i tennene, kan
              fyllinger gjenopprette tannstrukturen.
            </li>
            <li>
              <b>Bonding</b> - En spesiell komposittmasse kan legges på
              eksponerte tannhalser for å beskytte dem.
            </li>
            <li>
              <b>Tannkjøttbehandling</b> - I noen tilfeller kan kirurgiske
              inngrep være nødvendig for å dekke eksponerte tannhalser.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Hva koster det å behandle ising i tennene?</h2>
          <p>
            Prisen for behandling avhenger av årsaken til isingen. En
            undersøkelse hos tannlegen vil avdekke hva som forårsaker isingen og
            hvilken behandling som er nødvendig. Noen behandlinger, som
            fluorlakk og tannfyllinger, er relativt rimelige, mens mer
            omfattende prosedyrer som tannkjøttkirurgi kan være dyrere.
          </p>
          <p>
            I visse tilfeller kan du søke om Helfo-refusjon. Dette gjelder
            dersom isingen skyldes spesifikke medisinske tilstander, som
            tannkjøttsykdom eller alvorlig tannslitasje. Tannlegen din kan
            hjelpe deg med å finne ut om du har rett på refusjon. Dersom
            behandlingen dekkes av Helfo, har tannlegen direkteoppgjør, og du
            betaler kun din egenandel.
          </p>
        </div>
        <div className="mb-8">
          <h2>Ta grep for å beskytte tennene dine</h2>
          <p>
            Ising i tennene kan være både plagsomt og smertefullt, men det
            finnes gode løsninger for å redusere eller eliminere ubehaget. Ved å
            ta vare på tannhelsen, bruke riktig tannpleie og oppsøke tannlege
            ved behov, kan du beskytte tennene dine og unngå fremtidige
            problemer. Hvis du opplever hyppig eller vedvarende ising i tennene,
            anbefales det å ta kontakt med tannlegen for en grundig vurdering.
            Jo tidligere problemet blir identifisert, desto enklere er det å
            behandle det!
          </p>
          <p>
            Hos oss på Lade Tannhelsenter kan du komme innom for en sjekk - vi
            holder til i Trondheim på Lade, rett ved City Lade kjøpesenter.
          </p>
        </div>

        <div className="center-content mt-12 mb-10">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default Ising
