// Assets
import { Helmet } from "react-helmet"
import OrderButton from "../base/order-button/order-button"
import GnissingImg from "../../assets/images/articles/gnissing.webp"

const Gnissing = () => {
  return (
    <div className="info w-full center-content p-8 text-dark">
      <Helmet>
        <title>
          Tanngnissing og bittskinne: Årsaker, behandling og refusjon
        </title>
        <meta
          name="description"
          content="Tanngnissing kan føre til slitasje og smerter i kjeven. Lær hvordan en bittskinne kan beskytte tennene, redusere ubehag og om du kan få HELFO-refusjon."
        />
        <link
          rel="canonical"
          href="https://ladetannhelsesenter.no/info/gnissing"
        />
      </Helmet>
      <div className="max-w-screen-xl">
        <div className="center-content flex-col mb-8 text-center">
          <h1>Tanngnissing og bittskinne: Årsaker, behandling og refusjon</h1>
          <p className={`!mb-8 mt-2`}>
            Tanngnissing kan føre til slitasje og smerter i kjeven. Lær hvordan
            en bittskinne kan beskytte tennene, redusere ubehag og om du kan få
            HELFO-refusjon.
          </p>
          <img
            src={GnissingImg}
            alt="gnissing tannlege trondheim lade tannhelsesenter"
            className="w-full max-h-[60vh] object-cover object-center"
          />
        </div>
        <div className="mb-8">
          <h2>Hva er tanngnissing, og hvordan kan en bittskinne hjelpe?</h2>
          <p>
            Tanngnissing, også kalt bruksisme, er en tilstand der man ubevisst
            gnisser eller presser tennene mot hverandre. Dette skjer oftest om
            natten, men noen opplever det også i våken tilstand. Over tid kan
            gnissing av tennene føre til slitasje på tennene, smerter i kjeve,
            hodepine og spenninger i ansiktet. En vanlig og effektiv løsning er
            behandling med bittskinne, som beskytter tennene og reduserer
            belastningen på kjeven.
          </p>
          <h3>Tegn på tanngnissing</h3>
          <p>
            Mange er ikke klar over at de gnisser tenner før de opplever
            symptomer eller får det påvist hos tannlegen. Vanlige tegn på
            tanngnissing inkluderer:
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li>
              Slitasje på tennene, som kan gjøre dem kortere eller mer følsomme
            </li>
            <li>Smerter eller ømhet i kjeven, spesielt om morgenen</li>
            <li>Hodepine, spesielt i tinningene</li>
            <li>Spenninger i ansikt og nakke</li>
            <li>Klikkelyder eller ubehag ved bevegelse av kjeven</li>
            <li>
              Søvnforstyrrelser, både hos den som gnisser og eventuelle partnere
              som hører lydene
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Hva forårsaker tanngnissing?</h2>
          <p>
            Årsakene til tanngnissing kan variere fra person til person. Noen
            vanlige årsaker inkluderer:
          </p>
          <ul className="list-disc pl-8 mb-4 marker:font-bold">
            <li>
              <b>Stress og angst:</b> Psykiske faktorer er ofte den viktigste
              årsaken til tanngnissing. Stressede personer har større
              sannsynlighet for å gnisse tenner, spesielt om natten.
            </li>
            <li>
              <b>Feil bitt eller skjeve tenner:</b> En ujevn tannstilling kan
              føre til økt belastning på enkelte tenner, noe som kan utløse
              gnissing.
            </li>
            <li>
              <b>Søvnforstyrrelser:</b> Søvnapné og andre søvnforstyrrelser er
              ofte assosiert med tanngnissing.
            </li>
            <li>
              <b>Medisiner og stimulanter:</b> Enkelte medisiner, som
              antidepressiva, samt koffein og alkohol kan øke risikoen for
              tanngnissing.
            </li>
          </ul>
          <h2>Hvordan fungerer en bittskinne?</h2>
          <p>
            En bittskinne er en tynn plate laget av hard plast som tilpasses
            individuelt for å passe over tennene. Skinnen brukes vanligvis om
            natten og fungerer som en beskyttelse som hindrer direkte kontakt
            mellom over- og underkjeven. På denne måten beskytter tennene mot
            slitasje og reduserer belastningen på kjevemuskulaturen.
          </p>
          <p>
            I enkelte tilfeller kan det være nødvendig å bruke en bittskinne
            også i våken tilstand, spesielt hvis gnissingen skjer på dagtid.
            Behandling med bittskinne gir ofte rask lindring av symptomer som
            smerter og spenninger i kjeven.
          </p>
          <h3>Fordeler med å bruke en bittskinne</h3>
          <p className="!mb-1">
            Å bruke en bittskinne gir flere fordeler både på kort og lang sikt:
          </p>
          <ul className="list-disc pl-8 mb-4 marker:font-bold">
            <li>
              <b>Beskytter tennene:</b> Skinnen hindrer slitasje og skader på
              tenner som følge av gnissing.
            </li>
            <li>
              <b>Reduserer smerter og spenninger:</b> Behandling med bittskinne
              avlaster kjevemuskulaturen og reduserer smerter i kjeven, hodet og
              ansiktet.
            </li>
            <li>
              <b>Forebygger tannproblemer:</b> Ved å forhindre slitasje kan
              skinnen bidra til å unngå behovet for omfattende tannbehandlinger
              i fremtiden.
            </li>
            <li>
              <b>Forbedrer søvnkvaliteten:</b> Mange opplever bedre søvn når de
              ikke lenger våkner med smerter eller spenninger i kjeven.
            </li>
          </ul>
          <h3>Tanngnissing- bittskinne pris: hva koster behandlingen?</h3>
          <p>
            Prisen på en bittskinne kan variere avhengig av hvor du får
            behandlingen og hvilken type skinne som benyttes. Generelt ligger
            bittskinne pris mellom 2 000 og 5 000 kroner. Kostnaden inkluderer
            vanligvis både undersøkelse og tilpasning av skinnen. Selv om dette
            kan virke som en høy engangskostnad, er det viktig å huske at en
            bittskinne kan forhindre kostbare skader på tennene som følge av
            langvarig gnissing.
          </p>
        </div>
        <div className="mb-8">
          <h2>Bittskinne HELFO refusjon: kan du få økonomisk støtte?</h2>
          <p>
            I enkelte tilfeller kan du få refusjon fra HELFO
            (Helseøkonomiforvaltningen) for bittskinne. For å ha rett til
            refusjon må gnissingen ha forårsaket skader på tenner eller
            kjeveledd som krever behandling. Tannlegen vurderer om du oppfyller
            kriteriene, og ved behov hjelper de deg med søknaden om støtte fra
            HELFO.
          </p>
          <p>
            Refusjonsordningen gjelder ikke ved mildere tilfeller av
            tanngnissing, men i mer alvorlige tilfeller kan HELFO dekke deler av
            kostnaden. Dette gir rett til økonomisk støtte som gjør behandlingen
            mer overkommelig økonomisk.
          </p>
        </div>
        <div className="mb-8">
          <h2>Hvordan få behandling for tanngnissing?</h2>
          <p>
            Hvis du mistenker at du gnisser tenner, bør du oppsøke en tannlege
            for undersøkelse og behandling. Tannlegen kan identifisere tegn på
            tanngnissing, vurdere omfanget av eventuelle skader på tenner og
            anbefale riktig behandling. Prosessen for å få en bittskinne er som
            følger:
          </p>
          <ul className="list-decimal pl-8 mb-4 marker:font-bold">
            <li>
              <b>Undersøkelse:</b> Tannlegen undersøker tennene dine og vurderer
              om en bittskinne er nødvendig.
            </li>
            <li>
              <b>Avtrykk eller digital skanning:</b> For å lage en skinne som
              passer perfekt til tennene dine, tas det et avtrykk eller en
              digital skanning.
            </li>
            <li>
              <b>Tilpasning av skinnen:</b> Når skinnen er ferdigprodusert,
              tilpasses den nøye slik at den sitter godt og føles komfortabel.
            </li>
            <li>
              <b>Bruk og oppfølging:</b> Du får instruksjoner om hvordan du skal
              bruke og vedlikeholde skinnen. Ved behov kan tannlegen justere
              skinnen for å sikre optimal passform.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Bestill time hos tannlegen</h2>
          <p>
            Hvis du opplever tegn på tanngnissing, bør du ikke vente med å søke
            hjelp. En bittskinne kan forebygge alvorlige skader på tennene og gi
            deg en bedre hverdag uten smerter og ubehag. Bestill time hos
            tannlegen for en grundig undersøkelse og få vurdert om du har rett
            til refusjon fra HELFO, vi holder til sentralt i Trondheim, rett ved
            City Lade kjøpesenter.
          </p>
          Ved å ta grep i tide kan du beskytte tennene dine, redusere ubehag og
          unngå kostbare behandlinger i fremtiden.
          <p></p>
        </div>
        <div className="center-content mt-12 mb-10">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default Gnissing
