// Assets
import { Helmet } from "react-helmet"
import OrderButton from "../base/order-button/order-button"
import TMDImg from "../../assets/images/articles/tmd.webp"

const TMD = () => {
  return (
    <div className="info w-full center-content p-8 text-dark">
      <Helmet>
        <title>TMD og smerter i kjeveleddet: Symptomer og behandling</title>
        <meta
          name="description"
          content="Opplever du smerter i kjeven? Lær om symptomer, årsaker og behandling av TMD for å redusere smerter og forbedre kjeveleddfunksjonen."
        />
        <link rel="canonical" href="https://ladetannhelsesenter.no/info/tmd" />
      </Helmet>
      <div className="max-w-screen-xl">
        <div className="center-content flex-col mb-8 text-center">
          <h1>TMD og smerter i kjeveleddet: Symptomer og behandling</h1>
          <p className={`!mb-8 mt-2`}>
            Opplever du smerter i kjeven? Lær om symptomer, årsaker og
            behandling av TMD for å redusere smerter og forbedre
            kjeveleddfunksjonen.
          </p>
          <img
            src={TMDImg}
            alt="TMD tannlege trondheim lade tannhelsesenter"
            className="w-full max-h-[60vh] object-cover object-center"
          />
        </div>
        <div className="mb-8">
          <h2>Hva er TMD?</h2>
          <p>
            TMD, eller temporomandibulær dysfunksjon, er en tilstand som
            påvirker kjeveleddet og musklene som styrer bevegelsen av kjeven.
            Kjeveleddet, også kjent som temporomandibulærleddet (of the jaw),
            spiller en viktig rolle når vi snakker, tygger og lukker munnen. Når
            dette leddet ikke fungerer som det skal, kan det føre til smerter,
            stivhet og ubehag.
          </p>
          <p>
            Personer med TMD kan oppleve alt fra milde til intense smerter i
            kjeven, og i noen tilfeller kan smerten også spre seg til andre ledd
            i kroppen, som nakke og skuldre. Tilstanden kan påvirke
            livskvaliteten betydelig, men heldigvis finnes det effektive metoder
            for TMD behandling som kan redusere smerter og forbedre funksjonen i
            kjeven.
          </p>
          <h3>Årsaker til TMD</h3>
          <p>
            Det finnes flere årsaker til TMD, og tilstanden kan skyldes både
            fysiske og psykiske faktorer. Noen av de vanligste årsakene
            inkluderer:
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li>
              <b>Tanngnissing og sammenbiting:</b> Overdreven gnissing eller
              pressing av tennene kan belaste kjeveleddet og musklene rundt det.
            </li>
            <li>
              <b>Stress og spenninger:</b> Psykisk stress kan føre til
              muskelspenninger i kjeven, noe som kan forverre TMD symptomer.
            </li>
            <li>
              <b>Skader eller traumer:</b> Slag eller skader mot kjeven kan føre
              til betennelse og smerter i kjeveleddet.
            </li>
            <li>
              <b>Feil bittstilling:</b> En ujevn tannstilling kan føre til feil
              belastning på kjeveleddet.
            </li>
            <li>
              <b>Leddsykdommer:</b> Tilstander som leddgikt kan også påvirke
              kjeveleddet og forårsake smerter.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>TMD symptomer</h2>
          <p>
            Symptomene på TMD kan variere fra person til person, men noen av de
            vanligste tegnene inkluderer:
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li>
              Smerter i kjeven, spesielt når man tygger, snakker eller gjesper
            </li>
            <li>
              Smerter i tannkjøtt og kjeve, som kan kjennes rundt tannen eller
              spre seg til andre områder i ansiktet
            </li>
            <li>
              Klikkelyder eller knasing fra kjeveleddet når man åpner eller
              lukker munnen (lyder fra kjeveledd er vanlig ca.)
            </li>
            <li>Vanskeligheter med å åpne eller lukke munnen helt</li>
            <li>Følelse av stivhet eller blokkering i kjeven</li>
            <li>
              Hodepine, øresmerter eller svimmelhet som følge av spenninger i
              kjeven
            </li>
          </ul>
          <p>
            I de fleste tilfeller er symptomene midlertidige og kan behandles
            med enkle tiltak. Men hvis smertene vedvarer eller forverres, kan
            det være nødvendig med mer omfattende utredning og behandling hos
            tannlegen.
          </p>
          <h3>TMD behandling: hvordan redusere smerter</h3>
          <p>
            Behandling av TMD avhenger av årsaken til tilstanden og
            alvorlighetsgraden av symptomene. Her er noen av de mest effektive
            metodene som brukes for å redusere smerter og forbedre funksjonen i
            kjeveleddet:
          </p>
          <ul className="list-decimal pl-8 mb-4 marker:font-bold">
            <li>
              <p className="!mb-1">
                <b>Egenbehandling og livsstilsendringer</b>
              </p>
              <p className="!mb-1">
                I mange tilfeller kan TMD symptomer lindres ved å ta enkle grep
                i hverdagen. Dette kan inkludere:
              </p>
              <ul className="list-disc pl-8 mb-4">
                <li>Unngå harde eller seige matvarer som krever mye tygging</li>
                <li>
                  Redusere stress gjennom avslapningsteknikker som yoga eller
                  meditasjon
                </li>
                <li>Unngå overdreven gjesping eller biting av negler</li>
                <li>
                  Påføre varme- eller kuldepakker på kjeveområdet for å redusere
                  betennelse og smerter
                </li>
              </ul>
            </li>

            <li>
              <p className="!mb-1">
                <b>Behandling hos tannlegen</b>
              </p>
              <p className="!mb-1">
                Hvis egenbehandling ikke gir tilstrekkelig lindring, kan det
                være nødvendig å oppsøke tannlegen for profesjonell hjelp. Hos
                tannlegen kan du få følgende behandlinger:
              </p>
              <ul className="list-disc pl-8 mb-4">
                <li>
                  <b>Bittskinne:</b> En spesialtilpasset bittskinne som brukes
                  om natten kan hjelpe med å redusere belastningen på
                  kjeveleddet og beskytte tennene mot slitasje fra tanngnissing.
                </li>
                <li>
                  <b>Medikamentell behandling:</b> I enkelte tilfeller kan
                  betennelsesdempende medisiner eller muskelavslappende midler
                  brukes for å lindre smerter og stivhet.
                </li>
              </ul>
            </li>
            <li>
              <p className="!mb-1">
                <b>Behandling av underliggende årsaker</b>
              </p>
              <p className="!mb-1">
                Hvis TMD skyldes en underliggende tilstand som feil bittstilling
                eller leddgikt, kan det være nødvendig med mer spesialisert
                behandling. Dette kan inkludere tannregulering, kirurgisk
                inngrep eller behandling av betennelse i tannkjøttet.
              </p>
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Når bør du oppsøke tannlege?</h2>
          <p>
            Selv om TMD symptomer ofte går over av seg selv, er det viktig å
            oppsøke tannlege dersom du opplever vedvarende smerter eller
            problemer med å åpne og lukke munnen. Hos tannlegen kan du få en
            grundig utredning for å finne årsaken til symptomene og få riktig
            behandling for å redusere smerter og forbedre funksjonen i
            kjeveleddet.
          </p>
          <p>
            Andre symptomer krever vanligvis mer omfattende behandling, spesielt
            hvis smertene påvirker dagliglivet ditt. Ikke vent til smertene blir
            verre – bestill time hos oss i Lade Tannhelsesenter og få hjelp med
            TMD og smerter i kjeven. Du finner oss sentralt i Trondheim, rett
            ved City Lade.
          </p>
        </div>
        <div className="center-content mt-12 mb-10">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default TMD
