/* eslint-disable react-hooks/exhaustive-deps */
import { getAnalytics } from "firebase/analytics"
import { FirebaseOptions, initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { Fragment, useEffect, useState } from "react"
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom"
import AdminContext, { FirebaseUser } from "./contexts/admin-context"
import { AppContextProvider } from "./contexts/app-context"
import { adminService } from "./services/services"
import { getCookie } from "./utils/cookie"

// Components
import Admin from "./components/admin/admin"
import AdminSettings from "./components/admin/admin-settings"
import AdminTreatments from "./components/admin/admin-treatments"
import AdminBottomNav from "./components/admin/components/admin-bottom-nav"
import AdminChat from "./components/admin/components/admin-chat"
import AdminLogin from "./components/admin/components/admin-login"
import AdminNavbar from "./components/admin/components/admin-navbar"
import AdminTexts from "./components/admin/components/admin-texts"
import LoadingCover from "./components/base/loading-cover/loading-cover"
import Chat from "./components/chat/chat"
import ChatButton from "./components/chat/chat-button"
import CookieBanner from "./components/cookie-banner/cookie-banner"
import BottomInfo from "./components/dashboard/components/bottom-info"
import Navbar from "./components/navbar/navbar"
import { publicRoutes } from "./routes/public-routes"

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

// Check config for undefined values
Object.entries(firebaseConfig).forEach(([key, value]) => {
  if (value == null) {
    throw new Error(`Missing Firebase config value for ${key}`)
  }
})

export const firebaseApp = initializeApp(firebaseConfig)
export const firestore = getFirestore(firebaseApp)
export const analytics = getAnalytics(firebaseApp)

function ScrollToTop() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return null
}

const App = () => {
  const [Loading, setLoading] = useState(true)
  const [showChat, setShowChat] = useState(false)
  const [admin, setAdmin] = useState<FirebaseUser | null>(null)

  const isAdminPath = window.location.pathname.split("/")[1] === "ansatt"
  const showTopButtons = window.location.pathname !== "/bestill"

  // Mounted
  useEffect(() => {
    // Check if user has accepted cookies
    const _analysisConsent = getCookie("analysisConsent")
    // const _marketingConsent = getCookie("marketingConsent")  // Might be used in the future
    analytics.app.automaticDataCollectionEnabled = _analysisConsent === "false"

    const initPromises: Promise<any>[] = []

    if (isAdminPath) {
      initPromises.push(
        adminService
          .getAdmin()
          .then((res) => {
            setAdmin(res)
          })
          .catch((err) => {
            console.error(err.response)
          })
      )
    }

    Promise.all(initPromises).then(() => setLoading(false))
  }, [])

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0]
    if (showChat) body.classList.add("overflow-hidden", "md:overflow-auto")
    else body.classList.remove("overflow-hidden", "md:overflow-auto")
  }, [showChat])

  // Render
  if (isAdminPath && Loading) return <LoadingCover className="!h-screen" />

  const AdminRoutes = ({ children, ...rest }: any) => {
    if (!admin) return <Navigate to="/ansatt/login" />
    return <Outlet />
  }

  return (
    <div>
      <AppContextProvider>
        <AdminContext.Provider value={{ admin, setAdmin }}>
          <Router>
            <div
              className={!isAdminPath && showTopButtons ? "mb-48 md:mb-24" : ""}
            >
              <div className="mb-24">
                {!isAdminPath ? <Navbar /> : <AdminNavbar />}
              </div>
            </div>
            {!isAdminPath && (
              <Fragment>
                <ScrollToTop />
                <ChatButton show={!showChat} setShowChat={setShowChat} />
                <Chat show={showChat} setShowChat={setShowChat} />
              </Fragment>
            )}
            <div className={`w-full pb-[4rem] md:pb-0`}>
              <Routes>
                {/* Public routes */}
                {publicRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}

                {/* Admin routes */}
                <Route path="ansatt/login" element={<AdminLogin />} />
                <Route path="ansatt" element={<AdminRoutes />}>
                  <Route index element={<Admin />} />
                  <Route path="chat/:chatId?" element={<AdminChat />} />
                  <Route path="innstillinger" element={<AdminSettings />} />
                  <Route path="tekster" element={<AdminTexts />} />
                  <Route path="behandlinger" element={<AdminTreatments />} />
                </Route>
              </Routes>
              {!isAdminPath && <BottomInfo />}
            </div>
            {admin && isAdminPath && <AdminBottomNav />}
          </Router>
        </AdminContext.Provider>
        {!admin && !isAdminPath && <CookieBanner />}
      </AppContextProvider>
    </div>
  )
}

export default App
